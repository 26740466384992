import React  from 'react';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { Link } from 'react-router-dom'
// viedos

const Work = () => {

  return (
    <section className='section bg-cover h-auto' id={'work'} >
      <div className='container mx-auto'>
      <div className='flex work-section flex-col lg:flex-col h-full gap-x-24 justify-center text-center'>
        <div>
        <motion.h1
            variants={fadeIn('up', 0.3)} initial="hidden"
            whileInView={'show'} viewport={{once: true, amount: 0.5}}
            className='h1 font-neue font-extrabold'>My Work</motion.h1>
        </div>
      
            
            <div className='grid gap-[40px] font-neue'>
                <div className='flex flex-row'>
                    <Link to='/work/all' className='flex-1 w-full h-full circle'>
                        <div className='tree-rings film'>
                        </div>
                        <div>
                        All
                            </div>
                            
                                 
                    </Link>
                    <Link to='/work/commercials' className='flex-1 w-full h-full circle'>
                    <div className='tree-rings film'>
                            
                        </div>
                        <div>
                            Commercials
                            </div>
                                    
                    </Link>
                </div>
                <div className='flex flex-row'>
                    <Link to='/work/tech' className='flex-1 w-full h-full circle'>
                    <div className='tree-rings film'>
                           
                        </div>
                        <div>
                            Tech/Corporate
                            </div>
                                    
                    </Link>
                    <Link to='/work/fashion' className='flex-1 w-full h-full circle'>
                    <div className='tree-rings film'>
                           
                        </div>
                        <div>
                            Fashion
                            </div>
                                    
                    </Link>
                </div>
            </div>
        </div>
        </div>
    </section>
  );
};

export default Work;

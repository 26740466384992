import React, { useState , lazy, Suspense, useEffect  } from 'react';
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";

import { motion } from 'framer-motion';


// Commercials posters
import shecommercePoster from '../media/poster/shecom.webp';
import Asaya from '../media/poster/asayaHome.webp';
import Nagwa from '../media/poster/nagwa2.webp';
import Oben from '../media/poster/Oben.webp';
import Comet from '../media/poster/comet2.jpg';
import phonePeDubai from '../media/poster/phonepe_dubai.webp';
import SpyK from '../media/poster/SpyK.webp';
import FTV from '../media/poster/FTV.webp';

import Arnav from '../media/poster/Arnav.webp';
import AsayaEverything from '../media/poster/AsayaEverything.webp';
import PhonePe from '../media/poster/PhonePe.webp';
import phonePe_Srilanka from '../media/poster/phonePe_Srilanka.webp';
import nothing from '../media/poster/nothing.webp';
import Insight from '../media/poster/Insight.webp';

import ReveriePoster from '../media/poster/Reverie.webp';
import To6 from '../media/poster/To6.webp';
import suave from '../media/poster/suave.webp';
import Rewind from '../media/poster/Rewind.webp';
import Maha from '../media/poster/Maha.webp';
import Phula from '../media/poster/Phula.webp';

import vegNonVegLogo from '../img/logos/VegNonVeg.png';
import TO6Logo from '../img/logos/Tribeof6.webp'
import demeLogo from '../img/logos/Deme.png'
import ModishLogo from '../img/logos/Modish.png'
import MokobaraLogo from '../img/logos/Mokobara.jpg'
import AsayaLogo from '../img/logos/Asaya.png'
import SimbaLogo from '../img/logos/Simba.png'
import SaykLogo from '../img/logos/Spy Hard Seltzer.png'
import keiflogo from '../img/logos/keif.webp'
import Nail_StationLogo from '../img/logos/Nail station.png'
import SculpLogo from '../img/logos/Sculp tattoos.png'
import DhahiaLogo from '../img/logos/Dhahia juice.png'
import Nothing from '../img/logos/Nothing.png';
import AcquaLogo from '../img/logos/Acqua.png';
import AtyabLogo from '../img/logos/Mello.png';
import ArnavLogo from '../img/logos/Arnav.PNG';
import { paths } from '../components/MediaPaths';
import Model from '../components/Model';
import HeaderWork from '../components/HeaderWork';
const ShortVideoWork = lazy(()=> import('../components/ShortVideoWork'));
// viedos



// const LazyImage = ({ src }) => {
//   const { ref, inView } = useInView({
//     triggerOnce: true, // Load image only once when it becomes visible
//     threshold: 0.5, // Trigger when 10% of the component is visible
//   });

//   return (
//     <div ref={ref} className='w-[97%]'>
//             {inView ? <img src={src} className='rounded-xl w-full h-full' alt='' loading='lazy'></img> : <div className='container flex justify-center items-center suspense-loader'></div>} 
//     </div>
//   );
// };

const AllWork = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modelVideoUrl, setModelVideoUrl] = useState(null);

    

    function setModalParam(isTrue, videoUrl) {
        setModalOpen(isTrue);
        setModelVideoUrl(videoUrl);
    }

    const [workHeading] = useState([
        {
            heading: 'All',
            categoryId: 'all'
        },
        {
            heading: 'Commercials',
            categoryId: 'commercials'
        },
        {
            heading: 'Tech/Corporate',
            categoryId: 'tech'
        },
        {
            heading: 'Fashion',
            categoryId: 'fashion'
        }
    ]);
    const [heading] = useState([
      {
        heading: 'All',
        className:'all',
        vedioData: [
          {
            videoLink: 'https://youtu.be/TujYvEV6oFo',
            title: 'Comet',
            poster: Comet
        },
        {
            videoLink: 'https://youtu.be/MyR755UZa60',
            title: 'Asaya - Welcome Home',
            poster: Asaya
        },
        {
          videoLink: 'https://www.youtube.com/watch?v=wK_WpwKxUrw',
          title: 'Oben',
          poster: Oben,
          type: 'main'
      },
        {
            videoLink: 'https://www.youtube.com/watch?v=_gCsy_AfxzI',
            title: 'PhonePe Dubai',
            poster: phonePeDubai,
            type: 'main'
        },
        
              {
                  videoLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
                  title: 'Spyk Seltzer',
                  poster: SpyK,
                  type: 'reel',
                  reelData: [
                    {
                      poster: paths.vegnonveg_poster,
                      vimeoLink: 'https://youtube.com/shorts/cROrrGdGBuo?feature=share',
                      redirectLink: 'https://www.instagram.com/reel/CvATxjUBbiI/?igsh=OWtyaWluZnBmeHdu',
                      title: 'Vegnonveg x Nike',
                      thumbnail: '',
                      logo: vegNonVegLogo,
                      class: 'down _01',
                      dimension: 'w-[37%] h-[19%]'
                    },
                    {
                      poster: paths.Mokodora_poster,
                      vimeoLink: 'https://youtube.com/shorts/CHywMTgTrXk?feature=share',
                      redirectLink: 'https://www.instagram.com/reel/CwKseqIJLME/?igsh=enUwZ2IwbWxzOWx4',
                      title: 'Mokobora',
                      thumbnail: '',
                      logo: MokobaraLogo,
                      class: 'down _01',
                      dimension: 'w-[55%]'
                    },
                    {
                      poster: paths.Crocs,
                      vimeoLink: 'https://youtube.com/shorts/cs9g4nb0AdY?feature=share',
                      redirectLink: 'https://www.instagram.com/reel/CwKseqIJLME/?igsh=enUwZ2IwbWxzOWx4',
                      title: 'Crocs',
                      thumbnail: '',
                      logo: MokobaraLogo,
                      class: 'down _01',
                      dimension: 'w-[55%]'
                    },
                    {
                      poster: paths.FormOfStyle_poster,
                      vimeoLink: 'https://youtube.com/shorts/acSUcqL53v4?feature=share',
                      redirectLink: 'https://www.instagram.com/reel/C0eD2FjhxHq/?igsh=c2xzbXVrcmRja2dn',
                      title: 'Modish',
                      thumbnail: '',
                      logo: ModishLogo,
                      class: 'down',
                      dimension: 'w-[50%] h-[22%]'
                    }
                  ]

              },
              {
                videoLink: 'https://youtu.be/owJYKAYxJSg',
                title: 'Reverie - Dance Fashion Film',
                poster: ReveriePoster,
                type: 'main'
            },
            {
              videoLink: 'https://youtu.be/OzCJ7m4waNk',
              title: 'Suave Mundane - A Fashion Film',
              poster: suave,
              type: 'main'
          },
            {
              videoLink: 'https://youtu.be/E8F7JJBM8Ew',
              title: 'Shecommerz - Brand Film',
              poster: shecommercePoster
          },
          
              {
                  videoLink: 'https://youtu.be/IIwiVc7mZ44',
                  title: 'Nagwa x Hopo TVC Kuwait',
                  poster: Nagwa
              },
              
              {
                videoLink: 'https://www.instagram.com/reel/C5QqnyNygyQ/?igsh=ZzF2eHZwN3diM2Zw',
                title: 'Nothing Phones Launch',
                poster: nothing,
                type: 'reel',
                reelData: [
                  {
                    poster: paths.Intuit,
                    vimeoLink: 'https://youtube.com/shorts/etR-8OiP6Mw?feature=share',
                    redirectLink: 'https://youtube.com/shorts/etR-8OiP6Mw?feature=share',
                    title: 'Intuit',
                    thumbnail: '',
                    logo: ArnavLogo,
                    class: 'down _01',
                    dimension: 'w-[50%]'
                  },
                  {
                    poster: paths.nothing_poster,
                    vimeoLink: 'https://vimeo.com/938799865',
                    redirectLink: 'https://vimeo.com/938799865',
                    title: 'Nothing Phones',
                    thumbnail: '',
                    logo: Nothing,
                    class: 'down _01',
                    dimension: 'w-[55%] h-[10%]'
                  },
                  {
                    poster: paths.amazon_CGI,
                    vimeoLink: 'https://youtube.com/shorts/5BV-gjOp4m0?feature=share',
                    redirectLink: 'https://youtube.com/shorts/5BV-gjOp4m0?feature=share',
                    title: 'Amazon',
                    thumbnail: '',
                    logo: Nothing,
                    class: 'down _01',
                    dimension: 'w-[55%] h-[10%]'
                  },
                  {
                    poster: paths.Deliveroo,
                    vimeoLink: 'https://youtube.com/shorts/D9ff6kkqa-8?feature=share',
                    redirectLink: 'https://youtube.com/shorts/D9ff6kkqa-8?feature=share',
                    title: 'Deliveroo',
                    thumbnail: '',
                    logo: Nothing,
                    class: 'down _01',
                    dimension: 'w-[55%] h-[10%]'
                  }]
            },
            {
              videoLink: 'https://youtu.be/9NF25PRnw_w',
              title: 'PhonePe Srilanka',
              poster: phonePe_Srilanka,
              type: 'main'
          },
            {
              videoLink: 'https://vimeo.com/899443517',
              title: 'PhonePe Event',
              poster: PhonePe
          },
          
        {
          videoLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
          title: 'Spyk Seltzer',
          poster: SpyK,
          type: 'reel',
          reelData: [
            {
              poster: paths.bgTribe_of_6_poster,
              vimeoLink: 'https://youtube.com/shorts/B9J541bsD5E?feature=share',
              redirectLink: 'https://www.instagram.com/reel/Cv91CRDh73p/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
              title: 'Tribe Of 6',
              thumbnail: '',
              logo: TO6Logo,
              class: 'down',
              dimension: 'w-[84%]'
            },
            {
              poster: paths.deme_poster,
              vimeoLink: 'https://youtube.com/shorts/WCVkLBGaIFk?feature=share',
              redirectLink: 'https://www.instagram.com/reel/C0TV9aMhnyU/?igsh=MWwxNnJoMHl5ZTllNg==',
              title: 'Deme',
              thumbnail: '',
              logo: demeLogo,
              class: 'down',
              dimension: 'w-[84%]'
            },
            {
              poster: paths.NishaXAsaya_poster,
              vimeoLink: 'https://vimeo.com/900518391',
              redirectLink: 'https://www.instagram.com/reel/C3AkuFku4L0/?igsh=MWVpaTV5YmFpbWlrbA==',
              title: 'NishaXAsaya',
              thumbnail: '',
              logo: AsayaLogo,
              class: 'up',
              dimension: 'w-[250%]'
            },
            {
              poster: paths.Simba_poster,
              vimeoLink: 'https://vimeo.com/938800014',
              redirectLink: 'https://www.instagram.com/reel/C3AkuFku4L0/?igsh=MWVpaTV5YmFpbWlrbA==',
              title: 'Simba',
              thumbnail: '',
              logo: SimbaLogo,
              class: 'down',
              dimension: 'w-[55%] h-[12%]'
            },
          ]

      },
          {
            videoLink: 'https://youtu.be/KnutZ7DQGJ4?si=g69R587yK7irhBm9',
            title: 'Asaya - Everything Melanin',
            poster: AsayaEverything,
            type: 'main'
        },
        {
            videoLink: 'https://youtu.be/ioSvaP0zUuQ',
            title: 'Percept Insight x Udaan',
            poster: Insight,
            type: 'main'
        },
        {
          videoLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
          title: 'Spyk Seltzer',
          poster: SpyK,
          type: 'reel',
          reelData: [
            {
              poster: paths.Keif_poster,
              vimeoLink: 'https://vimeo.com/901579158',
              redirectLink: 'https://www.instagram.com/reel/CfYiUgUB5LI/?igsh=MXV0Mzc5YTZ0Y216cQ==',
              title: 'Keif',
              thumbnail: '',
              logo: keiflogo,
              class: 'down _01',
              dimension: 'w-[55%]'
            },
            {
              poster: paths.Nail_Station_poster,
              vimeoLink: 'https://vimeo.com/938792138',
              redirectLink: 'https://www.instagram.com/tv/CZcBqVshNy5/?igsh=MnRyaTJwdG9jcm9i',
              title: 'Nail Station',
              thumbnail: '',
              logo: Nail_StationLogo,
              class: 'up',
              dimension: 'w-[61%] h-[11%]'
            },
            {
              poster: paths.Bhavani_poster,
              vimeoLink: 'https://vimeo.com/938799808',
              redirectLink: 'https://www.instagram.com/reel/Cm6j0Ymq80x/?igsh=Y3hxdnpxM3NrMno3',
              title: 'Bhavani',
              thumbnail: '',
              logo: SculpLogo,
              class: 'down',
              dimension: 'w-[50%] h-[28%]'
            },
            {
              poster: paths.SpyK_poster,
              vimeoLink: 'https://youtube.com/shorts/pWDWrSstdD0?feature=share',
              redirectLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
              title: 'SpyK',
              thumbnail: '',
              logo: SaykLogo,
              class: 'up',
              dimension: 'w-[48%] h-[11%]'
            }
          ]

      },
        {
            videoLink: 'https://vimeo.com/857542460',
            title: 'Arnav Jewellery Founder Interview',
            poster: Arnav
        },
        {
          videoLink: 'https://vimeo.com/857537991',
          title: 'FYT Foods - A home food delivery app',
          poster: FTV
      },
      {
        videoLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
        title: 'Spyk Seltzer',
        poster: SpyK,
        type: 'reel',
        reelData: [
          {
            poster: paths.Arnav_Personal_poster,
            vimeoLink: 'https://vimeo.com/938801042',
            redirectLink: 'https://vimeo.com/938801042',
            title: 'Arnav Jewellery',
            thumbnail: '',
            logo: ArnavLogo,
            class: 'down _01',
            dimension: 'w-[50%]'
          },
          {
            poster: paths.Acqua_poster,
            vimeoLink: 'https://vimeo.com/938977831',
            redirectLink: 'https://vimeo.com/938977831',
            title: 'Acqua',
            thumbnail: '',
            logo: AcquaLogo,
            class: 'down _01',
            dimension: 'w-[37%] h-[19%]'
          },
          {
            poster: paths.Mello_poster,
            vimeoLink: 'https://vimeo.com/938793505',
            redirectLink: 'https://vimeo.com/938793505',
            title: 'Mello',
            thumbnail: '',
            logo: AtyabLogo,
            class: 'down _01',
            dimension: 'w-[65%]'
          },
           {
            poster: paths.Dhahia_poster,
            vimeoLink: 'https://vimeo.com/939143933',
            redirectLink: 'https://www.instagram.com/tv/CaE82LQNFmD/?igsh=azM1bGF2bzBjaTE1',
            title: 'Dhahia',
            thumbnail: '',
            logo: DhahiaLogo,
            class: 'up',
            dimension: 'w-[90%] h-[15%]'
          }
        ]

    }
              
          ]
      },
        {
          heading: 'Commercials',
          className:'commercials',
          vedioData: [
                {
                    videoLink: 'https://youtu.be/TujYvEV6oFo',
                    title: 'Comet',
                    poster: Comet
                },
                {
                    videoLink: 'https://youtu.be/MyR755UZa60',
                    title: 'Asaya - Welcome Home',
                    poster: Asaya
                },
                {
                    videoLink: 'https://www.youtube.com/watch?v=_gCsy_AfxzI',
                    title: 'PhonePe Dubai',
                    poster: phonePeDubai,
                    type: 'main'
                },
                {
                    videoLink: 'https://www.youtube.com/watch?v=wK_WpwKxUrw',
                    title: 'Oben',
                    poster: Oben,
                    type: 'main'
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
                    title: 'Spyk Seltzer',
                    poster: SpyK,
                    type: 'reel',
                    reelData: [
                      {
                        poster: paths.Mokodora_poster,
                        vimeoLink: 'https://youtube.com/shorts/CHywMTgTrXk?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/CwKseqIJLME/?igsh=enUwZ2IwbWxzOWx4',
                        title: 'Mokobora',
                        thumbnail: '',
                        logo: MokobaraLogo,
                        class: 'down _01',
                        dimension: 'w-[55%]'
                      },
                      {
                        poster: paths.Crocs,
                        vimeoLink: 'https://youtube.com/shorts/cs9g4nb0AdY?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/CwKseqIJLME/?igsh=enUwZ2IwbWxzOWx4',
                        title: 'Crocs',
                        thumbnail: '',
                        logo: MokobaraLogo,
                        class: 'down _01',
                        dimension: 'w-[55%]'
                      },
                      {
                        poster: paths.bgTribe_of_6_poster,
                        vimeoLink: 'https://youtube.com/shorts/B9J541bsD5E?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/Cv91CRDh73p/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                        title: 'Tribe Of 6',
                        thumbnail: '',
                        logo: TO6Logo,
                        class: 'down',
                        dimension: 'w-[84%]'
                      },
                      {
                        poster: paths.FormOfStyle_poster,
                        vimeoLink: 'https://youtube.com/shorts/acSUcqL53v4?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/C0eD2FjhxHq/?igsh=c2xzbXVrcmRja2dn',
                        title: 'Modish',
                        thumbnail: '',
                        logo: ModishLogo,
                        class: 'down',
                        dimension: 'w-[50%] h-[22%]'
                      }
                    ]

                },
                
                {
                    videoLink: 'https://youtu.be/IIwiVc7mZ44',
                    title: 'Nagwa x Hopo TVC Kuwait',
                    poster: Nagwa
                },
                {
                    videoLink: 'https://youtu.be/owJYKAYxJSg',
                    title: 'Reverie - Dance Fashion Film',
                    poster: ReveriePoster,
                    type: 'main'
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
                    title: 'Spyk Seltzer',
                    poster: SpyK,
                    type: 'reel',
                    reelData: [{
                        // data: {
                        //   mp4: paths.Nail Station,
                        //   webm: paths.Nail_Station_webm
                        // },
                        poster: paths.Nail_Station_poster,
                        vimeoLink: 'https://vimeo.com/938792138',
                        redirectLink: 'https://www.instagram.com/tv/CZcBqVshNy5/?igsh=MnRyaTJwdG9jcm9i',
                        title: 'Nail Station',
                        thumbnail: '',
                        logo: Nail_StationLogo,
                        class: 'up',
                        dimension: 'w-[61%] h-[11%]'
                      },
                      {
                        poster: paths.vegnonveg_poster,
                        vimeoLink: 'https://youtube.com/shorts/cROrrGdGBuo?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/CvATxjUBbiI/?igsh=OWtyaWluZnBmeHdu',
                        title: 'Vegnonveg x Nike',
                        thumbnail: '',
                        logo: vegNonVegLogo,
                        class: 'down _01',
                        dimension: 'w-[37%] h-[19%]'
                      },
                      {
                        poster: paths.deme_poster,
                        vimeoLink: 'https://youtube.com/shorts/WCVkLBGaIFk?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/C0TV9aMhnyU/?igsh=MWwxNnJoMHl5ZTllNg==',
                        title: 'Deme',
                        thumbnail: '',
                        logo: demeLogo,
                        class: 'down',
                        dimension: 'w-[84%]'
                      },
                      {
                        // data: {
                        //   mp4: paths.NishaXAsaya,
                        //   webm: paths.NishaXAsaya_webm
                        // },
                        poster: paths.NishaXAsaya_poster,
                        vimeoLink: 'https://vimeo.com/900518391',
                        redirectLink: 'https://www.instagram.com/reel/C3AkuFku4L0/?igsh=MWVpaTV5YmFpbWlrbA==',
                        title: 'NishaXAsaya',
                        thumbnail: '',
                        logo: AsayaLogo,
                        class: 'up',
                        dimension: 'w-[250%]'
                      }
                    ]

                },
                {
                    videoLink: 'https://youtu.be/E8F7JJBM8Ew',
                    title: 'Shecommerz - Brand Film',
                    poster: shecommercePoster
                },
                {
                    videoLink: 'https://youtu.be/OzCJ7m4waNk',
                    title: 'Suave Mundane - A Fashion Film',
                    poster: suave,
                    type: 'main'
                }
                
            ]
        },
        {
            heading: 'Tech',
            className:'tech',
            vedioData: [
                {
                    videoLink: 'https://www.youtube.com/watch?v=_gCsy_AfxzI',
                    title: 'PhonePe Dubai',
                    poster: phonePeDubai,
                    type: 'all'
                },
                
                {
                    videoLink: 'https://youtu.be/E8F7JJBM8Ew',
                    title: 'Shecommerz - Brand Film',
                    poster: shecommercePoster,
                    type: 'all'
                },
                {
                    videoLink: 'https://www.instagram.com/reel/C5QqnyNygyQ/?igsh=ZzF2eHZwN3diM2Zw',
                    title: 'Nothing Phones Launch',
                    poster: nothing,
                    type: 'reel',
                    reelData: [
                      {
                        poster: paths.Intuit,
                        vimeoLink: 'https://youtube.com/shorts/etR-8OiP6Mw?feature=share',
                        redirectLink: 'https://youtube.com/shorts/etR-8OiP6Mw?feature=share',
                        title: 'Intuit',
                        thumbnail: '',
                        logo: ArnavLogo,
                        class: 'down _01',
                        dimension: 'w-[50%]'
                      },
                      {
                        poster: paths.nothing_poster,
                        vimeoLink: 'https://vimeo.com/938799865',
                        redirectLink: 'https://vimeo.com/938799865',
                        title: 'Nothing Phones',
                        thumbnail: '',
                        logo: Nothing,
                        class: 'down _01',
                        dimension: 'w-[55%] h-[10%]'
                      },
                      {
                        poster: paths.amazon_CGI,
                        vimeoLink: 'https://youtube.com/shorts/5BV-gjOp4m0?feature=share',
                        redirectLink: 'https://youtube.com/shorts/5BV-gjOp4m0?feature=share',
                        title: 'Amazon',
                        thumbnail: '',
                        logo: Nothing,
                        class: 'down _01',
                        dimension: 'w-[55%] h-[10%]'
                      },
                      {
                        poster: paths.Deliveroo,
                        vimeoLink: 'https://youtube.com/shorts/D9ff6kkqa-8?feature=share',
                        redirectLink: 'https://youtube.com/shorts/D9ff6kkqa-8?feature=share',
                        title: 'Deliveroo',
                        thumbnail: '',
                        logo: Nothing,
                        class: 'down _01',
                        dimension: 'w-[55%] h-[10%]'
                      }]
                },
                {
                    videoLink: 'https://youtu.be/ioSvaP0zUuQ',
                    title: 'Percept Insight x Udaan',
                    poster: Insight,
                    type: 'main'
                },
                {
                    videoLink: 'https://youtu.be/9NF25PRnw_w',
                    title: 'PhonePe Srilanka',
                    poster: phonePe_Srilanka,
                    type: 'main'
                },
                {
                    videoLink: 'https://youtu.be/KnutZ7DQGJ4?si=g69R587yK7irhBm9',
                    title: 'Asaya - Everything Melanin',
                    poster: AsayaEverything,
                    type: 'main'
                },
                {
                    videoLink: 'https://vimeo.com/899443517',
                    title: 'PhonePe Event',
                    poster: PhonePe,
                    type: 'main'
                },
                {
                    videoLink: 'https://vimeo.com/857542460',
                    title: 'Arnav Jewellery Founder Interview',
                    poster: Arnav
                },
            ]
        },
        {
            heading: 'Fashion',
            className:'fashion',
            vedioData: [
                {
                    videoLink: 'https://youtu.be/TujYvEV6oFo',
                    title: 'Comet',
                    poster: Comet,
                    type: 'all'
                },
                {
                    videoLink: 'https://youtu.be/MyR755UZa60',
                    title: 'Asaya - Welcome Home',
                    poster: Asaya,
                    type: 'all'
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CvATxjUBbiI/?igsh=OWtyaWluZnBmeHdu',
                    title: 'Tribeof6 - Dubai',
                    poster: To6,
                    type: 'reel',
                    reelData: [{
                        poster: paths.vegnonveg_poster,
                        vimeoLink: 'https://youtube.com/shorts/cROrrGdGBuo?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/CvATxjUBbiI/?igsh=OWtyaWluZnBmeHdu',
                        title: 'Vegnonveg x Nike',
                        thumbnail: '',
                        logo: vegNonVegLogo,
                        class: 'down _01',
                        dimension: 'w-[37%] h-[19%]'
                      },
                  
                      {
                        poster: paths.bgTribe_of_6_poster,
                        vimeoLink: 'https://youtube.com/shorts/B9J541bsD5E?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/Cv91CRDh73p/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                        title: 'tribe Of 6',
                        thumbnail: '',
                        logo: TO6Logo,
                        class: 'down',
                        dimension: 'w-[84%]'
                      },
                      {
                        poster: paths.Nail_Station_poster,
                        vimeoLink: 'https://vimeo.com/938792138',
                        redirectLink: 'https://www.instagram.com/tv/CZcBqVshNy5/?igsh=MnRyaTJwdG9jcm9i',
                        title: 'Nail Station',
                        thumbnail: '',
                        logo: Nail_StationLogo,
                        class: 'up',
                        dimension: 'w-[61%] h-[11%]'
                      },
                      {
                        poster: paths.FormOfStyle_poster,
                        vimeoLink: 'https://vimeo.com/890606836',
                        redirectLink: 'https://www.instagram.com/reel/C0eD2FjhxHq/?igsh=c2xzbXVrcmRja2dn',
                        title: 'FormOfStyle',
                        thumbnail: '',
                        logo: ModishLogo,
                        class: 'down',
                        dimension: 'w-[50%] h-[22%]'
                      }]
                },
                {
                    videoLink: 'https://youtu.be/owJYKAYxJSg',
                    title: 'Reverie - Dance Fashion Film',
                    poster: ReveriePoster,
                    type: 'main'
                },
                {
                    videoLink: 'https://youtu.be/OzCJ7m4waNk',
                    title: 'Suave Mundane - A Fashion Film',
                    poster: suave,
                    type: 'main'
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CvATxjUBbiI/?igsh=OWtyaWluZnBmeHdu',
                    title: 'Tribeof6 - Dubai',
                    poster: To6,
                    type: 'reel',
                    reelData: [{
                        poster: paths.deme_poster,
                        vimeoLink: 'https://youtube.com/shorts/WCVkLBGaIFk?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/C0TV9aMhnyU/?igsh=MWwxNnJoMHl5ZTllNg==',
                        title: 'Deme',
                        thumbnail: '',
                        logo: demeLogo,
                        class: 'down',
                        dimension: 'w-[84%]'
                      },
                      {
                        poster: paths.Crocs,
                        vimeoLink: 'https://youtube.com/shorts/cs9g4nb0AdY?feature=share',
                        redirectLink: 'https://www.instagram.com/reel/CwKseqIJLME/?igsh=enUwZ2IwbWxzOWx4',
                        title: 'Crocs',
                        thumbnail: '',
                        logo: MokobaraLogo,
                        class: 'down _01',
                        dimension: 'w-[55%]'
                      },
                      {
                        poster: paths.NishaXAsaya_poster,
                        vimeoLink: 'https://vimeo.com/900518391',
                        redirectLink: 'https://www.instagram.com/reel/C3AkuFku4L0/?igsh=MWVpaTV5YmFpbWlrbA==',
                        title: 'NishaXAsaya',
                        thumbnail: '',
                        logo: AsayaLogo,
                        class: 'up',
                        dimension: 'w-[250%]'
                      },
                      {
                        poster: paths.Bhavani_poster,
                        vimeoLink: 'https://vimeo.com/938799808',
                        redirectLink: 'https://www.instagram.com/reel/Cm6j0Ymq80x/?igsh=Y3hxdnpxM3NrMno3',
                        title: 'Bhavani',
                        thumbnail: '',
                        logo: SculpLogo,
                        class: 'down',
                        dimension: 'w-[50%] h-[28%]'
                      }]
                },
                {
                    videoLink: 'https://vimeo.com/857542620',
                    title: 'Rewind - A fashion Film',
                    poster: Rewind
                },
                {
                    videoLink: 'https://vimeo.com/857581206',
                    title: 'Maha Ghalayani - Fashion Editorial',
                    poster: Maha
                },
                {
                    videoLink: 'https://drive.google.com/file/d/1pXyy3FLqhcWtfNEVTgbNxB-RsvHbIYd9/view',
                    title: 'Phula - Fashion Editorial',
                    poster: Phula
                }
            ]
        }
    ])

    const { id } = useParams();
    const [categoryId, setCategoryId] = useState(id);
    // useEffect(() => {
    //   window.scrollTo(0, 0); // Scrolls to the top-left of the page
    // }, []);

  return (
    <section className='section bg-cover h-auto' id={'AllWork'} >
        <HeaderWork />
      <div className='container mx-auto'>
        <div className='flex work-section flex-row lg:flex-row h-full lg:gap-x-24 gap-x-6 sm:gap-x-8 justify-center text-center'>
            {
                workHeading.map((workHeading, indexTop) => (
                    <div onClick={() => setCategoryId(workHeading.categoryId)}key={indexTop} className='commercial'>
                        <div
                        className='text-block-work'>
                            {workHeading.heading}
                        </div>
                    </div>
                    
                ))
            }
        </div>
        <div className='h-full m-[10px]'>
            {
                
                heading.map((workHeading, indexTop) => (
                    workHeading.vedioData.map((video, index) => (
                        <>
                            {
                                video.type && video.type === 'reel' ? (
                                    <div className={(categoryId === workHeading.className ? 'inline-block w-[-webkit-fill-available] p-[10px]': 'hidden')} >
                                      <Suspense fallback={<div className='container flex justify-center items-center suspense-loader'></div>}>
                                        <ShortVideoWork reels={video.reelData} play={categoryId === workHeading.className}/>
                                      </Suspense>
                                    </div>
                        ) : (
                                <div onClick={() => setModalParam(true, video.videoLink)} key={index}
                                className={'long-form-work-item inline-block p-[10px] h-[100%] ' + (categoryId === workHeading.className ? 'inline-block' : 'hidden')}
                                rel="noreferrer">
                                  {/* <LazyImage src={video.poster} alt=''/> */}
                                    <div className='w-[97%]'>
                                            <img src={video.poster} className='rounded-xl w-full h-full' alt='' loading='lazy'></img>
                                    </div>
                                    <div className='work-item-heading'> {video.title}</div>
                                </div>
                        )
                            }
                        </>
                    ))
                ))
                
            }
            
        </div>
        </div>
        <Model
        url={modelVideoUrl} // Replace with a valid video URL
        show={isModalOpen}
        onHide={() => setModalOpen(false)}
      />
    </section>
  );
};

export default AllWork;

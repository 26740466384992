import React from 'react';
// import components
import Logo from '../img/header/headerLogo.png'
import MobileNavWork from './MobileNavWork';
import { Link } from 'react-router-dom'

const HeaderWork = () => {
  return (
  <header className={`${"bg-black h-[100px]" } ${'fixed w-full px-[30px] lg:px-[100px] z-30 flex items-center'}`}>
    <div className='flex flex-col lg:flex-row items-center w-full justify-center'>
      {/* nav - initially hidden - show on desktop mode */}
      <nav className='hidden lg:flex gap-x-12 font-extrabold font-neue left-[-45%] relative'>
      <Link to='/'
              activeClass='active'
              smooth={true}
              spy={true}
              className={`"text-[#ffffff]"} ${'cursor-pointer text-[22px]  transition'}`}
              >
          Home
        </Link>
      </nav>
      {/* logo */}
      <Link to='/'
              activeClass='active'
              smooth={true}
              spy={true} className={`${'max-w-[170px] cursor-pointer'}`}>
        <img src={Logo} alt='gautammonangifilms logo'/>
      </Link>
    </div>
     <MobileNavWork />
  </header>
  );
};

export default HeaderWork;

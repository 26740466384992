import React, { useEffect, useRef } from 'react';
// import components
import Logo from '../img/header/headerLogo.png'
import MobileNav from './MobileNav';

// import Link
import { Link } from 'react-scroll';

const DefaultHeader = ({isHeaderChange}) => {
  const preloadedImage = useRef();

useEffect(() => {
    preloadedImage.current = new Image();
    preloadedImage.current.src = Logo;
}, []);
  return (
  <header className={`${isHeaderChange ? "bg-black h-[100px]" : "h-[100px]"} ${'fixed w-full px-[30px] lg:px-[100px] z-30 flex items-center'}`}>
    <div className='flex flex-col lg:flex-row items-center w-full justify-between'>
      {/* nav - initially hidden - show on desktop mode */}
      <nav className='hidden lg:flex gap-x-12 font-extrabold font-neue'>
        <Link to='home'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30} className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Home
        </Link>
        <Link to='shorts'
              activeClass='active'
              smooth={true}
              spy={true} className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Shorts
        </Link>
        <Link to='work'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30} className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Work
        </Link>
        <Link to='about'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30}  className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          About
        </Link>
        <Link to='contact'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={5000}  className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Contact
        </Link>
      </nav>
      {/* logo */}
      <Link to='home'
              activeClass='active'
              smooth={true}
              spy={true} className={`${'max-w-[170px] cursor-pointer'}`}>
        <img src={Logo} alt='gautammonangifilms logo'/>
      </Link>
      {/* <a href='#' className={`${isHeaderChange ? "" : "video-bg"} ${'max-w-[50px]'}`}>
        <img src={blackLogo} alt='gautammonangifilms logo' loading='lazy'/>
      </a> */}
    </div>
     <MobileNav isHeaderChange={isHeaderChange}/>
  </header>
  );
};

export default DefaultHeader;

// images for about
import g from '../img/about/g.jpg';
import Prithvi_Raj from '../img/about/Prithvi_Raj.jpeg';
import Tanmay_Biyani from '../img/about/Tanmay_Biyani.jpg';
import Aravind_DOP from '../img/about/Aravind_DOP.jpg';
import Aswin from '../img/about/Aswin.jpg';
import Tiara from '../img/about/Tiara.jpg';
import Dhruv_Kashyap from '../img/about/Dhruv_Kashyap.jpg';


import vegnonveg_poster from '../media/poster/reels/vegnonveg.jpg'
import Simba_poster from '../media/poster/reels/Simba.jpg';
import bgTribe_of_6_poster from '../media/poster/reels/bgTribe_of_6.jpg';
import deme_poster from '../media/poster/reels/deme.jpg';
import Mokodora_poster from '../media/poster/reels/Mokodora.jpg'
import FormOfStyle_poster from '../media/poster/reels/FormOfStyle.jpg'
import NishaXAsaya_poster from '../media/poster/reels/NishaXAsaya.jpg'
import SpyK_poster from '../media/poster/reels/SpyK.jpg';
import Keif_poster from '../media/poster/reels/Keif.jpg';
import Nail_Station_poster from '../media/poster/reels/Nail_Station.jpg'
import Bhavani_poster from '../media/poster/reels/Bhavani.jpg';
import Dhahia_poster from '../media/poster/reels/Dhahia.jpg';
import nothing_poster from '../media/poster/reels/nothing.jpg'
import Arnav_Personal_poster from '../media/poster/reels/Arnav_Personal.jpg'
import Acqua_poster from '../media/poster/reels/Acqua.jpg'
import Crocs from '../media/poster/reels/Crocs.webp'
import amazon_CGI from '../media/poster/reels/amazon_CGI.webp'
import Intuit from '../media/poster/reels/Intuit.webp'
import Mello_poster from '../media/poster/reels/Mello.jpg'
import Deliveroo from '../media/poster/reels/Deliveroo.webp'

export const paths = {

    vegnonveg_poster: vegnonveg_poster,
    bgTribe_of_6_poster: bgTribe_of_6_poster,
    deme_poster:deme_poster,
    FormOfStyle_poster: FormOfStyle_poster,
    Mokodora_poster: Mokodora_poster,
    NishaXAsaya_poster: NishaXAsaya_poster,
    Simba_poster: Simba_poster,
    SpyK_poster: SpyK_poster,
    Keif_poster: Keif_poster,
    Nail_Station_poster: Nail_Station_poster,
    Bhavani_poster: Bhavani_poster,
    Dhahia_poster: Dhahia_poster,
    nothing_poster: nothing_poster,
    Arnav_Personal_poster: Arnav_Personal_poster,
    Acqua_poster: Acqua_poster,
    Mello_poster: Mello_poster,
    amazon_CGI: amazon_CGI,
    Crocs: Crocs,
    Intuit: Intuit,
    Deliveroo: Deliveroo

};

export const collaboratorImages = {
    g: g,
    Prithvi_Raj: Prithvi_Raj,
    Tanmay_Biyani: Tanmay_Biyani,
    Aravind_DOP: Aravind_DOP,
    Tiara: Tiara,
    Aswin: Aswin,
    Dhruv_Kashyap: Dhruv_Kashyap
};
import React, { useEffect, useRef, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
//import components
import DefaultHeader from './components/DefaultHeader';
import Home from './pages/Home';
import About from './pages/About';
import Shorts from './pages/Shorts';
import Contact from './pages/Contact';
import Work from './pages/Work';
import AllWork from './pages/AllWork';

const App = () => {
  
  return (
    <>
    <Router>
      <Layout>
       <Routes>
          <Route path="/work/:id" element={<AllWork />} />
        </Routes>
      </Layout>
    </Router>
    </>
  )
};

const Layout = ({ children }) => {
  const location = useLocation();

  // Check if the current route is "/work"
  let isWorkPage = false;
  if (location.pathname.includes("/work/all")
    || location.pathname.includes("/work/tech")
    || location.pathname.includes("/work/fashion")
    || location.pathname.includes("/work/commercials")
  ) {
    isWorkPage = true;
  }

  const sectionRef = useRef(null);
  // const location = useLocation();

  const handleSectionRef = (ref) => {
    sectionRef.current = ref;
  };
  const [isHeaderChange, setIsHeaderChange] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && sectionRef.current && sectionRef.current.clientHeight) {
      window.addEventListener("scroll", () =>
       setIsHeaderChange(window.pageYOffset > sectionRef.current.clientHeight)
      );
    }
  }, []);

  return (
    <>
      {/* Render other sections only if not on the Work page */}
      {!isWorkPage && (
        <>
          <DefaultHeader isHeaderChange={isHeaderChange} />
          <Home onSectionRef={handleSectionRef}/>
          <Shorts />
          <Work/>
          <About/>
          <Contact/>
        </>
      )}

      {/* Main content */}
      <main>{children}</main>
    </>
  );
};

export default App;

import React, { useRef, useState } from 'react';
// import  motion
import { motion } from 'framer-motion';
import emailjs from "@emailjs/browser";
import { fadeIn } from '../variants';
import Socials from '../components/Socials';

const Contact = () => {
  const formRef = useRef();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const ref = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fr6476x",
        "template_mkye92k",
        formRef.current,
        "YDoKD3FGzb7PXOCVs"
      )
      .then(
        (result) => {
          // notify();
          setSuccess(true)
        },
        (error) => {
          setError(true);
        }
      );
  };
  return <section id='contact' ref={ref} className='section bg-cover h-auto'>
    <div className='container mx-auto'>
      <div className='flex flex-col pt-36 items-center justify-start gap-x-8 text-center lg:text-left pb-8'>
        <div className='px-4'>
          <motion.div 
           variants={fadeIn('up', 0.3)} initial="hidden"
           whileInView={'show'} viewport={{once: true, amount: 0.5}}
          className='h1 font-neue font-extrabold'>Let's make magic</motion.div>
          
          <motion.div  variants={fadeIn('up', 0.3)} initial="hidden"
          whileInView={'show'} viewport={{once: true, amount: 0.5}} className='flex mb-8 text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
            <Socials/>
          </motion.div>
          
          <motion.form ref={formRef}
          variants={fadeIn('right', 0.3)} initial="hidden"
          whileInView={'show'} viewport={{once: true, amount: 0.5}}
          className='flex flex-col gap-y-4'
          onSubmit={sendEmail} >
            <div className='flex gap-x-10'>
              <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 
                placeholder:text-[#757879]'
                type='text'
                name='name'
                placeholder='Name / Company'>
              </input>
              <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 
                placeholder:text-[#757879]'
                type='text'
                name='email'
                placeholder='Your email address'>
              </input>
            </div>
            <div className='flex gap-x-10'>
            <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 
                placeholder:text-[#757879]'
                type='text'
                name='phone'
                placeholder='Phone'>
              </input>
              <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 
                placeholder:text-[#757879]'
                type='text'
                name='from'
                placeholder='How did you hear about us?'>
              </input>
            </div>
            <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 
                placeholder:text-[#757879]'
                type='text'
                name='message'
                placeholder='Your message'>
            </input>
            <div className='flex mb-2 text-[14px] gap-x-6 max-w-max mx-auto lg:mx-0'>
              <div>
                Email: gautammonangi@gmail.com
              </div>
              <div>
                Phone: +7204023529
              </div>
          </div>
          <div className='text-[14px] max-w-max mx-auto lg:mx-0 mb-2'>
            <div>
              Location: Koramangala, Bangalore, India
            </div>
          </div>
            <button className='btn text-black bg-white mb-[px] mx-auto lg:mx-0 self-start'>
                    SEND
            </button>
          </motion.form>
        </div>
      </div>
    </div>
    
  </section>;
};

export default Contact;
